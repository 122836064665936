import React from 'react';
import { graphql, Link } from 'gatsby';
import Page from '../components/scenes/Page/Page/Page';
import LinkButton from '../components/components/LinkButton/LinkButton';
import Box from '../components/components/Box/Box';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from "gatsby-plugin-mdx";


const shortcodes = { Box, LinkButton, Link };

export default function Document( { data, pageContext } ) {
  const doc = data.mdx;

  return (
    <Page
      heading={ doc.frontmatter.title }
      desc={ doc.frontmatter.desc }
      pageContext={ pageContext }
      isDocument
    >
      <MDXProvider components={ shortcodes }>
        <MDXRenderer>{ doc.body }</MDXRenderer>
      </MDXProvider>
    </Page>
  );
}

export const query = graphql`
  query($slug: String!) {
    mdx( fields: { slug: { eq: $slug } } ) {
      body
      frontmatter {
        desc
        title
      }
    }
  }
`;